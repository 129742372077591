<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12 d-flex flex-column">
      <WButton info
        customClass="ml-auto"
        v-if="!isDisable"
        @click="openPageModal(true)"
      >
        {{ $t('pageprofile.add') }}
      </WButton>

<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[-->
<!--          { value: 'name', label: $t('general.name') },-->
<!--          { value: 'description', label: $t('general.description') },-->
<!--          { value: 'title', label: $t('pageprofile.title') },-->
<!--          { value: 'url_id', label: $t('pageprofile.portalurl') }-->
<!--        ]"-->
<!--      />-->
      <Table-pagination-v3
        v-if="showTablePaginationV3Component"
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[
          { value: 'name', label: $t('general.name') },
          { value: 'description', label: $t('general.description') },
          { value: 'title', label: $t('pageprofile.title') },
          { value: 'url_id', label: $t('pageprofile.portalurl') }
        ]"
      />
            <div class="row">
              <div class="col-lg-4">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <WButton secondary outline sm
                      v-if="isFiltersActive"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </WButton>
                  </div>
                </div>
              </div>

      </div>
    </div>
    <div v-if="dataIteratorView === true" class="col-12">
      <br />
      <div class="card-block p-0">
        <i class="glyphicons glyphicons-invoice glyphicons--shrink mr-0"></i>
        {{ $t('pageprofile.pages') }}
        <span class="text-muted ml-3" v-if="portalPagesList.length">
          {{ this.portalPagesList.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalResultPortalPages }}
        </span>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-12 col-md-12 order-lg-1 order-md-2">
          <!--          <div v-if="isPortalPagesLoading" class="loader loader-backdrop loader-backdrop-portalads" />-->
          <div v-if="isPortalPagesLoading"><Loader-spinner centered></Loader-spinner></div>
          <div class="row">
            <data-iterator-card
              justify-content-start
              v-for="page in portalPagesList"
              :title="page.name"
              :key="page.id"
              class="col-sm-6 col-md-4 col-lg-3 col-12 portal-pages-page-card"
            >
              <template #header-icons>
                <span class="icon-button-hover px-q d-flex align-items-center" v-if="page.url_id">
                  <a
                    target="_blank"
                    v-tooltip="{ content: $t('pageprofile.openPage') }"
                    @click.stop
                    :href="`${baseURL}${page.url_id}/?testing=true`"
                    :alt="$t('pageprofile.portalurl')"
                  >
                    <i class="fa fa-lg fa-external-link"></i>
                  </a>
                </span>
                <span
                  class="icon-button-hover px-q d-flex align-items-center"
                  @click="openPageModal(false, page)"
                  v-tooltip="{ content: $t('pageprofile.edit') }"
                >
                  <i class="fa fa-lg fa-pencil-square-o" />
                </span>
              </template>
              <template #card-body>
                <div>
                  <div>
                    <div class="mt-h">
                      <div class="data-iterator-data-categories pb-q">{{ $t('general.description') }}:</div>
                      <div
                        class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description break-all"
                        v-if="page.description"
                      >
                        <span>{{ page.description }}</span>
                      </div>
                      <div
                        class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description"
                        v-else
                      >
                        {{ $t('portalProfiles.noDescription') }}
                      </div>
                    </div>
                    <div class="mt-h">
                      <div class="data-iterator-data-categories pb-q">{{ $t('pageprofile.title') }}:</div>
                      <div
                        class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description break-all"
                        v-if="page.title"
                      >
                        <span>{{ page.title }}</span>
                      </div>
                      <div
                        class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description"
                        v-else
                      >
                        {{ $t('portalProfiles.noDescription') }}
                      </div>
                    </div>
                    <div v-if="page.url_id" class="mt-h">
                      <div class="data-iterator-data-categories">
                        <a
                          target="_blank"
                          @click.stop
                          :href="`${baseURL}${page.url_id}/?testing=true`"
                          :alt="$t('pageprofile.portalurl')"
                        >
                          {{ $t('pageprofile.portalurl') }}
                        </a>
                      </div>
                    </div>
                    <div class="mt-h">
                      <div class="data-iterator-data-categories pb-q">{{ $t('pageprofile.interface') }}:</div>
                      <div class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description">
                        <WButton info outline sm
                          customClass="mr-h mb-h text-nowrap"
                          :disabled="page.interface.logo_url === ''"
                          v-tooltip.top-center="{
                            content:
                              page.interface.logo_url !== ''
                                ? `<img src='${baseURL}${page.url_id}/${page.interface.logo_url}' alt=''
                            class='img-responsive img-thumbnail'
                            />`
                                : '',
                            html: true
                          }"
                        >
                          <i class="fa fa-eye mr-h"></i>
                          {{ $t('pageprofile.logo') }}
                        </WButton>
                        <WButton info outline sm
                          customClass="mr-h mb-h text-nowrap"
                          :disabled="page.interface.logo_footer_url === ''"
                            v-tooltip.top-center="{
                              content:
                                page.interface.logo_footer_url !== ''
                                  ? `<img src='${baseURL}${page.url_id}/${page.interface.logo_footer_url}' alt=''
                            class='img-responsive img-thumbnail'/>`
                                  : ''
                          }"
                        >
                          <i class="fa fa-eye mr-h"></i>
                          {{ $t('pageprofile.logo_footer') }}
                        </WButton>
                        <WButton info outline sm
                          customClass="mr-h mb-h text-nowrap"
                          :disabled="page.interface.background_url === ''"
                          v-tooltip.top-center="{
                            content:
                              page.interface.background_url !== ''
                                ? `<img src='${baseURL}${page.url_id}/${page.interface.background_url}' alt=''
                          class='img-responsive img-thumbnail'
                          />`
                                : ''
                          }"
                        >
                          <i class="fa fa-eye mr-h"></i>
                          {{ $t('pageprofile.back') }}
                        </WButton>
                      </div>
                    </div>

                    <!--                    <div v-if="profile.condition.cpe.length > 0">-->
                    <!--                      <div class="data-iterator-data-categories">{{ $t('general.cpes') }}:</div>-->
                    <!--                      <div class="custom-padding-for-in-iterator-card-data-items">-->
                    <!--                        <h5 v-if="profile.condition.cpe.length > 0">-->
                    <!--                          <span class="badge badge-success font-weight-normal" v-if="profile.condition.cpe.length > 0">-->
                    <!--                            {{ getCpeName(profile.condition.cpe[0]) }}-->
                    <!--                          </span>-->
                    <!--                          <span-->
                    <!--                            class="badge badge-dark font-weight-normal"-->
                    <!--                            v-if="profile.condition.cpe.length > 1"-->
                    <!--                            v-tooltip.top-center="{-->
                    <!--                              content: showAllCpes(profile.condition.cpe)-->
                    <!--                            }"-->
                    <!--                          >-->
                    <!--                            +{{ profile.condition.cpe.length - 1 }}-->
                    <!--                          </span>-->
                    <!--                        </h5>-->
                    <!--                        <div v-else>{{ $t('portalProfiles.noCPEs') }}</div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </template>
              <template #card-footer></template>
            </data-iterator-card>
          </div>
        </div>

      </div>
      <div
        v-if="
          this.canLoadMorePortalPages &&
          this.portalPagesList.length &&
          this.totalResultPortalPages - this.portalPagesList.length
        "
        class="card-block text-center p-0"
      >
        <WButton fullWidth
          @click="loadMore"
        >
          {{ this.$t('portal.paginationLoad') }}
          <span v-if="this.filters.limit < this.totalResultPortalPages - this.portalPagesList.length">
            {{ this.filters.limit }}
          </span>
          <span v-else>{{ this.totalResultPortalPages - this.portalPagesList.length }}</span>
          {{ this.$t('portal.paginationPagesOutOf') }}
          {{ this.totalResultPortalPages - this.portalPagesList.length }}
        </WButton>
      </div>
    </div>
    <div v-else class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portalpages" v-if="isPortalPagesLoading"></div>-->
        <div v-if="isPortalPagesLoading"><Loader-spinner /></div>
        <div class="card-header">
          <i class="glyphicons glyphicons-invoice glyphicons--shrink mr-0"></i>
          {{ $t('pageprofile.pages') }}
          <span class="text-muted ml-3" v-if="portalPagesList.length">
            {{ this.portalPagesList.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalResultPortalPages }}
          </span>
        </div>

        <div class="card-block card-block__table">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
          <div class="col-lg-12">-->
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.name') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.location') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.description') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('pageprofile.title') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('pageprofile.portalurl') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('pageprofile.interface') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="page.id" v-for="page in portalPagesList" @click="openPageModal(false, page)">
                <td class="align-middle">
                  <span v-if="page.name">
                    {{ page.name }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">{{ getLocationNameByPageId(page.id) }}</td>
                <td class="align-middle">{{ page.description }}</td>
                <td class="align-middle">{{ page.title }}</td>
                <td class="align-middle">
                  <!-- <a
                    target="_blank"
                    @click.stop
                    :href="`${baseURL}/api/portal-preview/${page.url_id}/?testing=true`"
                    :alt="$t('pageprofile.portalurl')"
                  >
                    <span class="d-block">{{ page.url_id }}</span>
                  </a> -->

                  <h5 v-if="page.url_id">
                    <a
                      target="_blank"
                      @click.stop
                      :href="`${baseURL}${page.url_id}/?testing=true`"
                      :alt="$t('pageprofile.portalurl')"
                    >
                      <span class="btn btn-sm btn-success">{{ page.url_id }}</span>
                    </a>
                  </h5>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle" style="padding-bottom: 0 !important;">
                  <span
                    class="mr-h mb-h text-nowrap btn btn-outline-info btn-sm"
                    v-tooltip.top-center="{
                      content: ` <img src='${baseURL}${page.url_id}/${page.interface.logo_url}' alt=''
                      class='img-responsive img-thumbnail'
                      />`
                    }"
                  >
                    <i class="fa fa-eye mr-h"></i>
                    {{ $t('pageprofile.logo') }}
                  </span>

                  <span
                    class="mr-h mb-h text-nowrap btn btn-outline-info btn-sm"
                    v-tooltip.top-center="{
                      content: ` <img src='${baseURL}${page.url_id}/${page.interface.logo_footer_url}' alt=''
                      class='img-responsive img-thumbnail'
                      />`
                    }"
                  >
                    <i class="fa fa-eye mr-h"></i>
                    {{ $t('pageprofile.logo_footer') }}
                  </span>

                  <span
                    class="mr-h mb-h text-nowrap btn btn-outline-info btn-sm"
                    v-tooltip.top-center="{
                      content: ` <img src='${baseURL}${page.url_id}/${page.interface.background_url}' alt=''
                      class='img-responsive img-thumbnail'
                      />`
                    }"
                  >
                    <i class="fa fa-eye mr-h"></i>
                    {{ $t('pageprofile.back') }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div>-->
          <div
            v-if="
              this.canLoadMorePortalPages &&
              this.portalPagesList.length &&
              this.totalResultPortalPages - this.portalPagesList.length
            "
            class="card-block text-center p-0"
          >
            <WButton fullWidth
              @click="loadMore"
            >
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultPortalPages - this.portalPagesList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultPortalPages - this.portalPagesList.length }}</span>
              {{ this.$t('portal.paginationPagesOutOf') }}
              {{ this.totalResultPortalPages - this.portalPagesList.length }}
            </WButton>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <modal
      class="modal-info modal-pages"
      v-model="isPageModalOpen"
      effect="fade/zoom"
      :backdrop="false"
      @click.native="handleModalClick"
      closeBtn
    >
      <h4 slot="modal-title" class="modal-title">
        <span>{{ isNewPage ? $t('pageprofile.new') : $t('pageprofile.edit') }}</span>
      </h4>

      <div v-if="updatedPage" class="d-flex justify-content-between">
        <div class="left-column-in-modal-portal-pages">
          <div class="form-group mt-h" v-if="!isNewPage">
            <label for="portal_url_input">{{ $t('pageprofile.portalurlID') }}</label>
            <div class="d-flex">
              <input
                type="text"
                :value="`${updatedPage.url_id}`"
                class="form-control"
                aria-describedby="portalurl-caption"
                id="portal_url_input"
                ref="portal_url_input"
                readonly
              />
              <WButton fullWidth
                @click="copyUrlFromInput('portal_url_input')"
                :aria-label="this.$t('general.copy')"
              >
                <i class="fa fa-copy"></i>
              </WButton>
            </div>
            <p class="text-muted d-block" id="portalurl-caption">{{ $t('pageprofile.portalurlCaption') }}</p>
          </div>

          <div class="form-group mt-h">
            <label for="name">{{ $t('general.name') }}</label>
            <input
              type="text"
              name="name"
              id="name"
              :placeholder="$t('general.name')"
              :data-vv-as="$t('general.name')"
              v-validate="'required|max:64'"
              class="form-control"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="updatedPage.name"
            />
            <span
              v-tooltip.top-center="{
                content: errors.first('name')
              }"
              v-show="errors.has('name')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>

          <div class="form-group mt-h">
            <label for="description">{{ $t('general.description') }}</label>
            <input
              type="text"
              name="description"
              id="description"
              :data-vv-as="$t('general.description')"
              :placeholder="$t('general.description')"
              class="form-control"
              v-validate="'max:100'"
              :class="{ input: true, 'is-danger': errors.has('description') }"
              v-model="updatedPage.description"
            />
            <span
              v-tooltip.top-center="{
                content: errors.first('description')
              }"
              v-show="errors.has('description')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>

          <div class="form-group mt-h">
            <label for="title">{{ $t('pageprofile.title') }}</label>
            <input
              type="text"
              name="title"
              :data-vv-as="$t('pageprofile.title')"
              v-validate="'max:64'"
              id="title"
              :placeholder="$t('pageprofile.title')"
              class="form-control"
              :class="{ input: true, 'is-danger': errors.has('title') }"
              v-model="updatedPage.title"
            />
            <span
              v-tooltip.top-center="{
                content: errors.first('title')
              }"
              v-show="errors.has('title')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>
          <!--Company-->
          <div class="form-group form-group mt-h" v-if="areLocationsVisible">
            <label for="company">{{ $t('general.company') }}</label>
            <select
              @change="changeCompany"
              v-model="updatedPageCompany"
              id="company"
              name="company"
              class="form-control"
            >
              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
              <option v-for="company in companys" :key="company.name" :value="company">
                {{ company.name }}
              </option>
            </select>
          </div>
          <!--Location-->
          <div class="form-group form-group mt-h" v-if="updatedPageCompany === 'All companys'" v-show="areLocationsVisible">
            <label for="location">{{ $t('general.location') }}</label>
            <select
              @change="changeLocationforSchedule"
              v-model="updatedPage.base_location"
              id="location"
              name="location"
              class="form-control"
            >
              <option v-for="location in locationsList" :key="location.id" :value="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>
          <!--          location when company selected-->
          <div class="form-group form-group mt-h" v-else v-show="areLocationsVisible">
            <label for="location">{{ $t('general.location') }}</label>
            <select
              @change="changeLocationforSchedule"
              v-model="updatedPage.base_location"
              id="location"
              name="location"
              class="form-control"
            >
              <option
                v-for="location in locationsObjbyIds(updatedPageCompany.locations)"
                :key="location.id"
                :value="location.id"
              >
                {{ location.name }}
              </option>
            </select>
          </div>
          <h6>{{ $t('pageprofile.imagesBlock') }}</h6>
          <div class="form-group mt-h d-flex flex-row flex-wrap images-for-portal-page">
            <div
              class="form-group mt-h d-flex flex-column justify-content-start align-items-center images-for-portal-page-item"
            >
              <!--            <div class="mr-auto">-->
              <!--              <label for="logo-upload" class="d-block">{{ $t('pageprofile.logo') }}</label>-->
              <!--              <div class="d-flex align-items-start">-->
              <!--                <div>-->
              <!--                  <span class="fileUpload btn btn-primary">-->
              <!--                    <i class="fa fa-file-image-o mr-h"></i>-->
              <!--                    <span :class="{ invisible: logoMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
              <!--                    <span v-if="logoMuteUpload" class="loader loader&#45;&#45;mini"></span>-->
              <!--                    <input-->
              <!--                      id="logo-upload"-->
              <!--                      name="files"-->
              <!--                      @change="uploadImageToServer('logo')"-->
              <!--                      type="file"-->
              <!--                      class="upload logo"-->
              <!--                    />-->
              <!--                  </span>-->
              <!--                  <small class="text-muted d-block">(jpg, png)</small>-->
              <!--                </div>-->

              <!--                <button-->
              <!--                  :disabled="!updatedPage.interface.logo && !updatedPage.interface.logo_url"-->
              <!--                  class="ml-h btn btn-danger"-->
              <!--                  @click="-->
              <!--                    (updatedPage.interface.logo = ''),-->
              <!--                      (updatedPage.interface.logo_url = ''),-->
              <!--                      showAndHideIframeNotification()-->
              <!--                  "-->
              <!--                >-->
              <!--                  {{ $t('general.delete') }}-->
              <!--                </button>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="text-center">
                <p class="d-block">{{ $t('pageprofile.logo') }}</p>
              </div>
              <div>
                <img
                  v-if="updatedPage.interface.logo || updatedPage.interface.logo_url"
                  :src="`${baseURLForImgs}/api/portal${
                    isUploaded.logo
                      ? `/static/${updatedPage.interface.logo}`
                      : `-preview/${updatedPage.url_id}/${updatedPage.interface.logo_url}`
                  }`"
                  alt="Upload new"
                  class="img-responsive img-thumbnail img-thumbnail-in-modal"
                />
                <img
                  v-else
                  src="/static/img/no-image-for-portal-pages.png"
                  alt="Upload new"
                  class="img-responsive img-thumbnail img-thumbnail-in-modal"
                />
              </div>
            </div>

            <div
              class="form-group mt-h d-flex flex-column justify-content-start align-items-center images-for-portal-page-item"
            >
              <!--            <div class="mr-auto">-->
              <!--              <label for="background-upload">{{ $t('pageprofile.backgroundInPreview') }}</label>-->
              <!--              <div class="d-flex align-items-start">-->
              <!--                <div>-->
              <!--                  <span class="fileUpload btn btn-primary" :class="{ disabled: backgroundMuteUpload }">-->
              <!--                    <i class="fa fa-file-image-o mr-h"></i>-->
              <!--                    <span :class="{ invisible: backgroundMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
              <!--                    <span v-if="backgroundMuteUpload" class="loader loader&#45;&#45;mini"></span>-->
              <!--                    <input-->
              <!--                      :disabled="backgroundMuteUpload"-->
              <!--                      id="background-upload"-->
              <!--                      name="files"-->
              <!--                      @change="uploadImageToServer('background')"-->
              <!--                      type="file"-->
              <!--                      class="upload background"-->
              <!--                    />-->
              <!--                  </span>-->
              <!--                  <small class="text-muted d-block">(jpg, png)</small>-->
              <!--                </div>-->

              <!--                <button-->
              <!--                  :disabled="!updatedPage.interface.background && !updatedPage.interface.background_url"-->
              <!--                  class="ml-h btn btn-danger"-->
              <!--                  @click="-->
              <!--                    (updatedPage.interface.background = ''),-->
              <!--                      (updatedPage.interface.background_url = ''),-->
              <!--                      showAndHideIframeNotification()-->
              <!--                  "-->
              <!--                >-->
              <!--                  {{ $t('general.delete') }}-->
              <!--                </button>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="text-center">
                <p>{{ $t('pageprofile.backgroundInPreview') }}</p>
              </div>
              <div>
                <img
                  v-if="updatedPage.interface.background || updatedPage.interface.background_url"
                  :src="`${baseURLForImgs}/api/portal${
                    isUploaded.background
                      ? `/static/${updatedPage.interface.background}`
                      : `-preview/${updatedPage.url_id}/${updatedPage.interface.background_url}`
                  }`"
                  alt="Upload new"
                  class="img-responsive img-thumbnail img-thumbnail-in-modal"
                />
                <img
                  v-else
                  src="/static/img/no-image-for-portal-pages.png"
                  alt="Upload new"
                  class="img-responsive img-thumbnail img-thumbnail-in-modal"
                />
              </div>
            </div>

            <div
              class="form-group mt-h d-flex flex-column justify-content-start align-items-center images-for-portal-page-item"
            >
              <!--            <div class="mr-auto">-->
              <!--              <label for="logo-footer-upload">{{ $t('pageprofile.logo_footer') }}</label>-->
              <!--              <div class="d-flex align-items-start">-->
              <!--                <div>-->
              <!--                  <span class="fileUpload btn btn-primary">-->
              <!--                    <i class="fa fa-file-image-o mr-h"></i>-->
              <!--                    <span :class="{ invisible: logo_footerMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
              <!--                    <span v-if="logo_footerMuteUpload" class="loader loader&#45;&#45;mini"></span>-->
              <!--                    <input-->
              <!--                      id="logo-footer-upload"-->
              <!--                      name="files"-->
              <!--                      @change="uploadImageToServer('logo_footer')"-->
              <!--                      type="file"-->
              <!--                      class="upload logo_footer"-->
              <!--                    />-->
              <!--                  </span>-->
              <!--                  <small class="text-muted d-block">(jpg, png)</small>-->
              <!--                </div>-->

              <!--                <button-->
              <!--                  :disabled="!updatedPage.interface.logo_footer && !updatedPage.interface.logo_footer_url"-->
              <!--                  class="ml-h btn btn-danger"-->
              <!--                  @click="-->
              <!--                    (updatedPage.interface.logo_footer = ''),-->
              <!--                      (updatedPage.interface.logo_footer_url = ''),-->
              <!--                      showAndHideIframeNotification()-->
              <!--                  "-->
              <!--                >-->
              <!--                  {{ $t('general.delete') }}-->
              <!--                </button>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="text-center">
                <p>{{ $t('pageprofile.logo_footer') }}</p>
              </div>
              <div>
                <img
                  v-if="updatedPage.interface.logo_footer || updatedPage.interface.logo_footer_url"
                  :src="`${baseURLForImgs}/api/portal${
                    isUploaded.logo_footer
                      ? `/static/${updatedPage.interface.logo_footer}`
                      : `-preview/${updatedPage.url_id}/${updatedPage.interface.logo_footer_url}`
                  }`"
                  alt="Upload new"
                  class="img-responsive img-thumbnail img-thumbnail-in-modal"
                />
                <img
                  v-else
                  src="/static/img/no-image-for-portal-pages.png"
                  alt="Upload new"
                  class="img-responsive img-thumbnail img-thumbnail-in-modal"
                />
              </div>
            </div>
          </div>
          <h6>{{ $t('pageprofile.theme') }}</h6>
          <Alert closable :text="$t('pageprofile.changeColorAlert')" class="alert-warning"></Alert>
          <div class="form-group portal-palette-wrapper">
            <Portal-palette
              :selected-color="updatedPage.interface.theme_color"
              @select-color="handleSelectColor"
              :selected-type="updatedPage.interface.theme_type"
              @select-type="handleSelectType"
            />
          </div>
          <p>{{ $t('pageprofile.themeTypeMinimalisticOrNot') }}</p>
          <div class="form-group mt-1 mb-0">
            <select v-model="updatedPage.interface.theme" class="form-control">
              <option v-for="theme of themes" :value="theme" :key="theme">
                {{ $t(`pageprofile.themeType-${theme}`) }}
              </option>
            </select>
            <!-- <Select-component
              v-model="updatedPage.interface.theme"
              track-by="name"
              :options="themes"
              :placeholder="$t('portalStats.selectTheme')"
            /> -->
          </div>
          <div class="form-group mt-2 mb-0">
            <Switch-component
              v-model="hasSupport"
              :disabled="isDisable"
              :label="$t('pageprofile.hasSupport')"
              id="has-support"
            />
          </div>

          <!--          <div class="form-group mt-2 mb-0">-->
          <!--            <select v-model="updatedPage.interface.theme" class="form-control">-->
          <!--              <option v-for="theme of themes" :value="theme" :key="theme">{{ theme }}</option>-->
          <!--            </select>-->
          <!--            &lt;!&ndash; <Select-component-->
          <!--              v-model="updatedPage.interface.theme"-->
          <!--              track-by="name"-->
          <!--              :options="themes"-->
          <!--              :placeholder="$t('portalStats.selectTheme')"-->
          <!--            /> &ndash;&gt;-->
          <!--          </div>-->

          <div v-if="hasSupport" class="form-group">
            <label for="support">{{ $t('pageprofile.support') }}</label>
            <input
              type="text"
              id="support"
              name="support"
              class="form-control input"
              :class="{ 'is-danger': errors.has('support') }"
              :disabled="isDisable"
              v-validate="'required'"
              v-model.trim="updatedPage.support"
            />
            <span
              v-tooltip.top-center="{ content: errors.first('support') }"
              v-show="errors.has('support')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error" />
            </span>
          </div>

        </div>
        <div>
          <div v-if="updatedPage.url_id" class="d-flex justify-content-end">
            <label
              class="btn btn-sm btn-outline-secondary ml-auto new-modern-style-btn-other-btn"
              :class="{ active: testingAuth }"
            >
              <input type="radio" name="activeFilter" @click="() => (testingAuth = !testingAuth)" />
              {{ $t('pageprofile.authorizationButton') }}
            </label>
          </div>

          <div class="ml-1" v-if="updatedPage.url_id">
            <div class="form-group portal-iframe-container">
              <div v-if="showIframeWrapper" id="iframe-wrapper-wrapper" class="iframe-wrapper-wrapper">
                <!--                <div v-if="isIframeLoading" class="loader loader-backdrop loader-backdrop-iframe" />-->
                <div v-if="isIframeLoading"><Loader-spinner centered></Loader-spinner></div>
                <div class="iframe-wrapper-notify" v-if="showIframeNotification">
                  <div role="button" class="iframe-wrapper-notify-close">
                    <i @click="hideIframeNotification" class="fa fa-remove"></i>
                  </div>
                  <div class="iframe-wrapper-notify-inner text-center">
                    {{ $t('pageprofile.imageUploadedNotification') }}
                  </div>
                </div>
                <div v-if="testingAuth === false" class="iframe-wrapper">
                  <iframe
                    @load="iframeLoaded"
                    id="preview-iframe"
                    ref="iframePreview"
                    width="100%"
                    height="100%"
                    scrolling="auto"
                    sandbox="allow-same-origin allow-scripts"
                    seamless
                    :src="`${baseURL}${updatedPage.url_id}/?testing=true&auth=${testingAuth}`"
                  ></iframe>
                  <div @mouseover="logoTopChangerMouseOver" class="logo-top-change-wrapper">
                    <div @mouseleave="logoTopChangerMouseLeave" v-show="logoTopChangerShow" class="logo-top-change">
                      <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesLogoTop') }}</div>
                      <div class="form-group mt-h d-flex">
                        <div class="mr-auto">
                          <div class="text-center">
                            <h5 class="logo-changers-color d-block">{{ $t('pageprofile.logo') }}</h5>
                          </div>
                          <div class="d-flex align-items-start">
                            <div>
                              <span class="fileUpload btn btn-primary new-modern-style-btn-other-btn">
                                <i class="icon-camera userphoto-icon big"></i>
                                <!--                        <span :class="{ invisible: logoMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                                <span v-if="logoMuteUpload" class="loader loader--mini"></span>
                                <input
                                  id="logo-upload-in-preview"
                                  name="files"
                                  @change="uploadImageToServer('logo', 'logo-in-preview')"
                                  type="file"
                                  class="upload logo-in-preview"
                                />
                              </span>
                              <small class="logo-changers-photo-formats-to-left logo-changers-color text-muted d-block">
                                (jpg, png)
                              </small>
                            </div>
                            <WButton danger
                              customClass="ml-h"
                              @click="
                                (updatedPage.interface.logo = ''),
                                (updatedPage.interface.logo_url = ''),
                                showAndHideIframeNotification()
                              "
                              :disabled="!updatedPage.interface.logo && !updatedPage.interface.logo_url"
                            >
                              <i class="fa fa-remove text-danger"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @mouseover="logoFooterChangerMouseOver" class="logo-footer-change-wrapper">
                    <div
                      @mouseleave="logoFooterChangerMouseLeave"
                      v-show="logoFooterChangerShow"
                      class="logo-footer-change"
                    >
                      <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesLogoFooter') }}</div>
                      <div class="form-group mt-h d-flex">
                        <div class="mr-auto">
                          <div class="text-center">
                            <h5 class="logo-changers-color">{{ $t('pageprofile.logo_footer') }}</h5>
                          </div>
                          <div class="d-flex align-items-start">
                            <div>
                              <span class="fileUpload btn btn-primary new-modern-style-btn-other-btn">
                                <i class="icon-camera userphoto-icon big"></i>
                                <!--                          <span :class="{ invisible: logo_footerMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                                <span v-if="logo_footerMuteUpload" class="loader loader--mini"></span>
                                <input
                                  id="logo-footer-upload-in-preview"
                                  name="files"
                                  @change="uploadImageToServer('logo_footer', 'logo_footer-in-preview')"
                                  type="file"
                                  class="upload logo_footer-in-preview"
                                />
                              </span>
                              <small class="logo-changers-photo-formats-to-left logo-changers-color text-muted d-block">
                                (jpg, png)
                              </small>
                            </div>
                            <WButton danger
                              customClass="ml-h"
                              @click="
                                (updatedPage.interface.logo_footer = ''),
                                (updatedPage.interface.logo_footer_url = ''),
                                showAndHideIframeNotification()
                              "
                              :disabled="!updatedPage.interface.logo_footer && !updatedPage.interface.logo_footer_url"
                            >
                              <i class="fa fa-remove text-danger"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @mouseover="backgroundChangerMouseOver" class="background-change-wrapper">
                    <div
                      @mouseleave="backgroundChangerMouseLeave"
                      v-show="backgroundChangerShow"
                      class="background-change"
                    >
                      <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesBackground') }}</div>
                      <div class="form-group mt-h d-flex">
                        <div class="mr-auto">
                          <div class="text-center">
                            <h5 class="logo-changers-color">{{ $t('pageprofile.backgroundInPreview') }}</h5>
                          </div>
                          <div class="d-flex align-items-start">
                            <div>
                              <span
                                class="fileUpload btn btn-primary new-modern-style-btn-other-btn"
                                :class="{ disabled: backgroundMuteUpload }"
                              >
                                <i class="icon-camera userphoto-icon big"></i>
                                <!--                              <span :class="{ invisible: backgroundMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                                <span v-if="backgroundMuteUpload" class="loader loader--mini"></span>
                                <input
                                  :disabled="backgroundMuteUpload"
                                  id="background-upload-in-preview"
                                  name="files"
                                  @change="uploadImageToServer('background', 'background-in-preview')"
                                  type="file"
                                  class="upload background-in-preview"
                                />
                              </span>
                              <small class="logo-changers-photo-formats-to-left text-muted d-block">(jpg, png)</small>
                            </div>
                            <WButton danger
                              customClass="ml-h"
                              @click="
                                (updatedPage.interface.background = ''),
                                (updatedPage.interface.background_url = ''),
                                showAndHideIframeNotification()
                              "
                              :disabled="!updatedPage.interface.background && !updatedPage.interface.background_url"
                            >
                              <i class="fa fa-remove text-danger"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <!-- 667x375 is iPhone 6 -->
                  <iframe
                    @load="iframeLoaded"
                    id="preview-iframe"
                    width="375"
                    height="667"
                    sandbox="allow-same-origin allow-scripts"
                    seamless
                    :src="`${baseURL}${updatedPage.url_id}/?testing=true&auth=${testingAuth}`"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-1" v-else>
            <div class="form-group portal-iframe-container">
              <div
                v-if="showIframeWrapper"
                id="iframe-wrapper-wrapper-for-new-page"
                class="iframe-wrapper-wrapper-for-new-page"
              >
                <!--                <div v-if="isIframeLoading" class="loader loader-backdrop loader-backdrop-iframe" />-->
                <div class="iframe-wrapper-notify-for-new-page" v-if="showIframeNotification">
                  <div role="button" class="iframe-wrapper-notify-close">
                    <i @click="hideIframeNotification" class="fa fa-remove"></i>
                  </div>
                  <div class="iframe-wrapper-notify-inner text-center">
                    {{ $t('pageprofile.imageUploadedNotification') }}
                  </div>
                </div>
                <div class="iframe-wrapper-for-new-page">
                  <iframe
                    @load="iframeLoaded"
                    id="preview-iframe"
                    width="100%"
                    height="100%"
                    scrolling="auto"
                    sandbox="allow-same-origin allow-scripts"
                    seamless
                    :src="newPagePlacholderImgWithLocalizationPath"
                  ></iframe>
                  <!--                  Тут ссылка на psd картинки-заглушки https://yadi.sk/i/FONXI8oSsYiybQ-->
                  <div @mouseover="logoTopChangerMouseOver" class="logo-top-change-wrapper-for-new-page">
                    <div @mouseleave="logoTopChangerMouseLeave" v-show="logoTopChangerShow" class="logo-top-change">
                      <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesLogoTop') }}</div>
                      <div class="form-group mt-h d-flex">
                        <div class="mr-auto">
                          <div class="text-center">
                            <h5 class="logo-changers-color d-block">{{ $t('pageprofile.logo') }}</h5>
                          </div>
                          <div class="d-flex align-items-start">
                            <div>
                              <span class="fileUpload btn btn-primary new-modern-style-btn-other-btn">
                                <i class="icon-camera userphoto-icon big"></i>
                                <!--                        <span :class="{ invisible: logoMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                                <span v-if="logoMuteUpload" class="loader loader--mini"></span>
                                <input
                                  id="logo-upload-in-preview"
                                  name="files"
                                  @change="uploadImageToServer('logo', 'logo-in-preview')"
                                  type="file"
                                  class="upload logo-in-preview"
                                />
                              </span>
                              <small class="logo-changers-photo-formats-to-left logo-changers-color text-muted d-block">
                                (jpg, png)
                              </small>
                            </div>
                            <WButton danger
                              customClass="ml-h"
                              @click="
                                (updatedPage.interface.logo = ''),
                                (updatedPage.interface.logo_url = ''),
                                showAndHideIframeNotification()
                              "
                              :disabled="!updatedPage.interface.logo && !updatedPage.interface.logo_url"
                            >
                              <i class="fa fa-remove text-danger"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @mouseover="logoFooterChangerMouseOver" class="logo-footer-change-wrapper-for-new-page">
                    <div
                      @mouseleave="logoFooterChangerMouseLeave"
                      v-show="logoFooterChangerShow"
                      class="logo-footer-change"
                    >
                      <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesLogoFooter') }}</div>
                      <div class="form-group mt-h d-flex">
                        <div class="mr-auto">
                          <div class="text-center">
                            <h5 class="logo-changers-color">{{ $t('pageprofile.logo_footer') }}</h5>
                          </div>
                          <div class="d-flex align-items-start">
                            <div>
                              <span class="fileUpload btn btn-primary new-modern-style-btn-other-btn">
                                <i class="icon-camera userphoto-icon big"></i>
                                <!--                          <span :class="{ invisible: logo_footerMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                                <span v-if="logo_footerMuteUpload" class="loader loader--mini"></span>
                                <input
                                  id="logo-footer-upload-in-preview"
                                  name="files"
                                  @change="uploadImageToServer('logo_footer', 'logo_footer-in-preview')"
                                  type="file"
                                  class="upload logo_footer-in-preview"
                                />
                              </span>
                              <small class="logo-changers-photo-formats-to-left logo-changers-color text-muted d-block">
                                (jpg, png)
                              </small>
                            </div>
                            <WButton danger
                              customClass="ml-h"
                              @click="
                                (updatedPage.interface.logo_footer = ''),
                                (updatedPage.interface.logo_footer_url = ''),
                                showAndHideIframeNotification()
                              "
                              :disabled="!updatedPage.interface.logo_footer && !updatedPage.interface.logo_footer_url"
                            >
                              <i class="fa fa-remove text-danger"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @mouseover="backgroundChangerMouseOver" class="background-change-wrapper-for-new-page">
                    <div
                      @mouseleave="backgroundChangerMouseLeave"
                      v-show="backgroundChangerShow"
                      class="background-change"
                    >
                      <div class="img-recommendations">{{ $t('imgAndVideoRecommendations.pagesBackground') }}</div>
                      <div class="form-group mt-h d-flex">
                        <div class="mr-auto">
                          <div class="text-center">
                            <h5 class="logo-changers-color">{{ $t('pageprofile.backgroundInPreview') }}</h5>
                          </div>
                          <div class="d-flex align-items-start">
                            <div>
                              <span
                                class="fileUpload btn btn-primary new-modern-style-btn-other-btn"
                                :class="{ disabled: backgroundMuteUpload }"
                              >
                                <i class="icon-camera userphoto-icon big"></i>
                                <!--                              <span :class="{ invisible: backgroundMuteUpload }">{{ $t('general.chooseFile') }}</span>-->
                                <span v-if="backgroundMuteUpload" class="loader loader--mini"></span>
                                <input
                                  :disabled="backgroundMuteUpload"
                                  id="background-upload-in-preview"
                                  name="files"
                                  @change="uploadImageToServer('background', 'background-in-preview')"
                                  type="file"
                                  class="upload background-in-preview"
                                />
                              </span>
                              <small class="logo-changers-photo-formats-to-left text-muted d-block">(jpg, png)</small>
                            </div>
                            <WButton danger
                              customClass="ml-h"
                              @click="
                                (updatedPage.interface.background = ''),
                                (updatedPage.interface.background_url = ''),
                                showAndHideIframeNotification()
                              "
                              :disabled="!updatedPage.interface.background && !updatedPage.interface.background_url"
                            >
                              <i class="fa fa-remove text-danger"></i>
                            </WButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <div>
          <WButton success outline
            customClass="mr-1"
            v-if="!isNewPage && !isDisable"
            @click="updatePage(updatedPage)"
            :disabled="errors.any() || muteEdit"
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
          <WButton success outline
            customClass="mr-1"
            v-else-if="!isDisable"
            @click="createPage(updatedPage)"
            :disabled="muteEdit || errors.any()"
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.create') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
          <WButton outline
            v-if="!isNewPage && !isDisable"
            @click="copyPage(updatedPage)"
            :disabled="errors.any() || muteEdit"
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
        </div>

        <div class="ml-1">
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNewPage && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="cancelEditMode"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleCloseDeleteDialog.bind(this)"
      :handle-delete="deletePage.bind(this)"
      :target-text="updatedPage.name"
    >
      <template v-slot:additional>
        <WarningLine class="mt-1 mb-0">
          <i18n path="pageprofile.warnUsage" tag="span" for="pageprofile.warnUsageAccent">
            <span class="font-weight-bold text-danger">{{ $t('pageprofile.warnUsageAccent') }}</span>
          </i18n>
        </WarningLine>
      </template>
    </delete-dialog>

    <modal
      title="Redirect Url"
      v-if="!isCurrentRedirectUrlEmpty"
      :value="!isCurrentRedirectUrlEmpty"
      class="modal-info"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('pageprofile.portalurl') }}</h4>
      <div class="text-center">
        <label for="new_url_input">{{ $t('pageprofile.saveurl') }}:</label>
        <div class="d-flex">
          <input
            type="text"
            :value="`${currentRedirectUrl}`"
            class="form-control"
            id="new_url_input"
            ref="new_url_input"
            readonly
          />
          <WButton
            @click="copyUrlFromInput('new_url_input')"
            :aria-label="this.$t('general.copy')"
          >
            <i class="fa fa-copy"></i>
          </WButton>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="currentRedirectUrl = false"
        >
          Ok
        </WButton>
      </div>
    </modal>

    <close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Multiselect } from 'vue-multiselect';
import Calendar from 'vue2-slot-calendar';
import { API_URL, BRANDING, BRANDS } from '@/config';
import Alert from '@/components/Universal/alert/alert.vue';
import CloseDialog from '../../components/close-dialog.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import helpers from '../../helpers';
import Modal from '../../components/Modal.vue';
import portalPagesService from '../../services/portalPagesService';
import PortalPalette from '../../components/Portal/portal-palette.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import WarningLine from '../../components/warning-line.vue';
import commonService from '../../services/commonService';
import DataIteratorCard from '../../components/Universal/data-iterator-card.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import DatePicker from '../../components/date-picker.vue';
import portalPagesScheduleService from '../../services/portalPagesScheduleService';
// import timeZones from './timeZones.json';
import timeZones from '@/timeZones';
import PageInScheduleList from '../../components/UI/PageInScheduleList.vue';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');

const watcher = new helpers.ChangesWatcher();
const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const TIME_OF_DAY = ['Morning', 'Afternoon', 'Evening', 'Night'];
const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export default {
  name: 'PortalPagesNew',
  components: {
    Alert,
    CloseDialog,
    DeleteDialog,
    EmptyCellValue,
    Modal,
    PortalPalette,
    TablePaginationV3,
    WarningLine,
    DataIteratorCard,
    SwitchComponent,
    DatePicker,
    Multiselect,
    Calendar,
    PageInScheduleList,
    // SelectComponent
  },
  data() {
    return {
      showTablePaginationV3Component: true,
      updatedPageCompany: 'All companys',
      calendarSelectedDate: new Date(),
      calendarSelectedDateDayOfWeek: new Date().getDay(),
      schedulesForShowingInCalendarBlock: [],
      schedulesForShowingInCalendarCreating: false,
      selectedTimeZone: false,
      newDaysObject: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      },
      updatedDaysObject: false,
      newHoursObject: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true
      },
      updatedHoursObject: false,
      showScheduleInfoMessage: false,
      showScheduleBlock: false,
      newSchedle: {
        page_id: '',
        page_url: '',
        loc_id: '', // ID локации
        schedule: {
          views: 0, // не используется
          start: moment().startOf('day').unix(), // start timestamp
          stop: moment().endOf('day').unix(), // stop timestamp
          utc_diff: 0, // смещение  по UTC
          dow_mask: 0, // маска day of week
          hour_mask: 0, // маска hour of day
          time_of_day: {
            // morning: true,
            // afternoon: true,
            // evening: true,
            // night: true
          },
          day_of_week: {
            // monday: true,
            // tuesday: true,
            // wednesday: true,
            // thursday: true,
            // friday: true,
            // saturday: true,
            // sunday: true
          }
        }
      },
      actionWithSchedule: 'none', // 'none, create, update, delete'
      updatedSchedle: false,
      datePickerInSchedule: {
        startDate: moment().startOf('day').valueOf(), // start timestamp
        endDate: moment().endOf('day').valueOf() // stop timestamp
      },
      iframeNotificationShowed: false,
      isIframeLoading: true,
      showIframeNotification: false,
      showIframeWrapper: true,
      backgroundChangerShow: false,
      logoTopChangerShow: false,
      logoFooterChangerShow: false,
      dataIteratorView: true,
      offset: 0,
      canLoadMorePortalPages: true,
      totalResultPortalPages: false,
      isPageModalOpen: false,
      isCloseModalOpen: false,
      isNewPage: false,
      updatedPage: false,
      isUploaded: {
        logo: false,
        logo_footer: false,
        background: false
      },
      testingAuth: false,
      isDeleteOpen: false,
      logoMuteUpload: false,
      logo_footerMuteUpload: false,
      backgroundMuteUpload: false,
      currentRedirectUrl: false,
      newPage: {
        base_location: this.$store.state.userData.base_location,
        name: '',
        description: '',
        title: '',
        interface: {
          logo: '',
          logo_footer: '',
          background: '',
          theme_type: '',
          theme_color: '',
          // отрефакторено: заменена window.CUSTOM_BUILD через конфиг-константы, а не this.isBeelineBranding для совместимости со старым кодом
          theme: BRANDING === BRANDS.beeline ? 'default-beeline' : 'default'
        },
        // agreement: '',
        support: ''
      },
      hasAgreement: false,
      hasSupport: false,
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }
      },
      // baseURL: `${API_URL ? '' : `${window.location.protocol}//`}${
      //   API_URL ? API_URL : window.location.host
      // }`
      baseURL: '',
      baseURLForImgs:''

    };
  },
  watch: {
    updatedPage: {
      deep: true,
      handler() {
        watcher.tick();
      }
    },
    updatedDaysObject: {
      deep: true,
      handler() {
        if (this.updatedDaysObject !== false) {
          this.updatedSchedle.schedule.dow_mask = this.dowMaskFromDaysObject(
            JSON.parse(JSON.stringify(this.updatedDaysObject))
          );
        }
      }
    },
    updatedHoursObject: {
      deep: true,
      handler() {
        if (this.updatedHoursObject !== false) {
          this.updatedSchedle.schedule.hour_mask = this.hourMaskFromHoursObject(
            JSON.parse(JSON.stringify(this.updatedHoursObject))
          );
        }
      }
    },
    actionWithSchedule(newVal, oldVal) {
      if ((newVal === 'create' && oldVal === 'none') || (newVal === 'delete' && oldVal === 'update')) {
        this.showScheduleInfoMessage = true;
      }
      if (newVal === 'delete' && oldVal === 'update') {
        this.showScheduleBlock = false;
      }
    },
    // dowMaskForSchedule() {
    //   // console.log('change')
    //   if (this.updatedSchedle !== false) {
    //     this.updatedSchedle.schedule.dow_mask = this.dowMaskForSchedule;
    //   }
    // },
    testingAuth() {
      this.isIframeLoading = true;
    },
    logoMuteUpload(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.logoTopChangerShow = false;
      }
    },
    logo_footerMuteUpload(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.logoFooterChangerShow = false;
      }
    },
    backgroundMuteUpload(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.backgroundChangerShow = false;
      }
    },
    'updatedPage.interface.theme_color': {
      handler() {
        this.showAlert = true;
      }
    },
    'updatedPage.interface.theme_type': {
      handler() {
        this.showAlert = true;
      }
    }
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    portalBaseUrl() {
      return this.$store.getters.portalBaseUrl;
    },
    portalBaseUrlForImgs() {
      return this.$store.getters.portalBaseUrlForImgs;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive(){
      return this.filters.limit !== 30 || this.filters.sort_by !== 'name' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    themes() {
      if (this.isBeelineBranding) {
        return ['default-beeline', 'minimalistic-beeline'];
      }
      return ['default', 'minimalistic'];
    },
    isloadingPortalPagesSchedules() {
      return this.$store.state.loadingPortalPagesSchedules;
    },
    isPortalPagesSchedulesForShowingReady() {
      // для спинера в блоке с рассписанием показа
      if (
        this.isloadingPortalPagesSchedules === true ||
        this.schedulesForShowingInCalendarCreating === true ||
        this.isPortalPagesLoading === true
      ) {
        return false;
      }
      return true;
    },
    isCurrentRedirectUrlEmpty() {
      if (this.currentRedirectUrl === false || this.currentRedirectUrl === '') {
        return true;
      }
      return false;
    },
    schedulesForShowingInCalendarBlockWithDowMaskMatchesSelectedDay() {
      // тут выводим только рассписания, маска дня которых разрешает им показ в выбраный день
      return this.schedulesForShowingInCalendarBlock.filter(
        (schedule) => schedule.injected.isShowingInSelectedDayOfWeek === true
      );
    },
    schedulesForShowingInCalendarBlockWithDowMaskNotMatchesSelectedDay() {
      // тут выводим только рассписания, маска дня которых запрещает им показ в выбраный день
      return this.schedulesForShowingInCalendarBlock.filter(
        (schedule) => schedule.injected.isShowingInSelectedDayOfWeek === false
      );
    },
    calendarSelectedDateDayStartTimestamp() {
      const date = this.calendarSelectedDate;
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      const ts = date.valueOf();
      return ts;
    },
    // dowMaskForSchedule() {
    //   // тут созадется битовая маска (битовая математика и все такое)
    //   let result = 0;
    //   if (this.updatedSchedle !== false) {
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('monday') && this.updatedSchedle.schedule.day_of_week.monday === true) {
    //       result = result + (1 << 1);
    //     }
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('tuesday') && this.updatedSchedle.schedule.day_of_week.tuesday === true) {
    //       result = result + (1 << 2);
    //     }
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('wednesday') && this.updatedSchedle.schedule.day_of_week.wednesday === true) {
    //       result = result + (1 << 3);
    //     }
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('thursday') && this.updatedSchedle.schedule.day_of_week.thursday === true) {
    //       result = result + (1 << 4);
    //     }
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('friday') && this.updatedSchedle.schedule.day_of_week.friday === true) {
    //       result = result + (1 << 5);
    //     }
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('saturday') && this.updatedSchedle.schedule.day_of_week.saturday === true) {
    //       result = result + (1 << 6);
    //     }
    //     if (this.updatedSchedle.schedule.day_of_week.hasOwnProperty('sunday') && this.updatedSchedle.schedule.day_of_week.sunday === true) {
    //       result = result + (1 << 0);
    //     }
    //   }
    //   return result;
    // },
    timeZonesOptions() {
      return timeZones;
    },
    HOURS() {
      return HOURS;
    },
    TIME_OF_DAY() {
      return TIME_OF_DAY;
    },
    DAYS_OF_WEEK() {
      return DAYS_OF_WEEK;
    },
    pagesSchedules() {
      return this.$store.state.portalPagesSchedulesList;
    },
    pagesSchedulesByPageId() {
      const result = {};
      const schedules = JSON.parse(JSON.stringify(this.pagesSchedules));
      for (const schedule of schedules) {
        result[schedule.page_id] = schedule;
      }
      return result;
    },
    isPageHasScheduleByPageId() {
      const result = {};
      for (const page of this.portalPagesList) {
        if (this.pagesSchedulesByPageId.hasOwnProperty(page.id)) {
          result[page.id] = true;
        } else {
          result[page.id] = false;
        }
      }
      return result;
    },
    newPagePlacholderImgWithLocalizationPath() {
      // Тут ссылка на psd картинки-заглушки https://yadi.sk/i/FONXI8oSsYiybQ
      if (this.$i18n.locale === 'ru') {
        return '/static/img/new-portal-page-placeholder-ru.png';
      }
      return '/static/img/new-portal-page-placeholder-en.png';
    },
    isImageLoading() {
      return this.logoMuteUpload === true || this.logo_footerMuteUpload === true || this.backgroundMuteUpload === true;
    },
    isPortalPagesLocationsLoading() {
      return this.$store.state.loadingPortalPagesLocations;
    },
    locationsListByPageId() {
      return this.$store.state.portalPagesLocationsList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    muteEdit() {
      return this.$store.state.mutePortalPageEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    portalPagesList() {
      return this.$store.state.portalPagesList;
    },
    isPortalPagesLoading() {
      return this.$store.state.loadingPortalPages;
    }
  },
  methods: {
    // resetAllFilters(){
    //   this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'name',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   }
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters(){
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        //location: {
        //  data: {},
        //  with_childs: false,
        // }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    changeCompany() {
      if (this.updatedPageCompany !== 'All companys') {
        // console.log('change')
        let firstLocationIdForCompany = '';
        if (this.updatedPageCompany.hasOwnProperty('locations') && this.updatedPageCompany.locations.length > 0) {
          firstLocationIdForCompany = this.updatedPageCompany.locations[0];
          // console.log(firstLocationIdForCompany);
        }
        this.updatedPage.base_location = firstLocationIdForCompany;
      }
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    clickOnPageNameInSchedule(pageId) {
      if (pageId && pageId !== '') {
        let pageForOpenInModal = false;
        for (const page of this.portalPagesList) {
          if (page.id === pageId) {
            pageForOpenInModal = page;
            break;
          }
        }
        if (pageForOpenInModal !== false) {
          this.openPageModal(false, pageForOpenInModal);
        }
      }
    },
    extractSchedulesForDate(dateInputed, schedules) {
      const result = [];
      // приводим дату к таймстампу начала дня
      const date = dateInputed;
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      const ts = parseInt(date.valueOf() / 1000);
      const schedulesForExtract = JSON.parse(JSON.stringify(schedules));
      for (const schedule of schedulesForExtract) {
        // console.log(schedule.schedule.start)
        // console.log(ts)
        // console.log(schedule.schedule.stop)
        // console.log(schedule.schedule.start < ts && ts < schedule.schedule.stop)
        if (schedule.schedule.start <= ts && ts <= schedule.schedule.stop - 1) {
          result.push(schedule);
        }
      }
      // добавляем имена страниц для отображения в интерфейсе
      this.injectFieldsInScheduleObject(result, date);
      return result;
    },
    injectFieldsInScheduleObject(schedules, inputedDate) {
      // let dayOfWeekNumber = inputedDate.getDay();
      for (const schedule of schedules) {
        schedule.injected = {};
        let pageName = false;
        for (const page of this.portalPagesList) {
          if (page.id === schedule.page_id) {
            pageName = page.name;
            break;
          }
        }
        schedule.injected.pageName = pageName;
        schedule.injected.daysOfWeekForShowing = this.daysObjectFromDowMask(schedule.schedule.dow_mask);
        schedule.injected.isShowingInSelectedDayOfWeek = this.isShowingInSelectedDayOfWeekByDowMask(
          schedule.schedule.dow_mask,
          inputedDate
        );
        schedule.injected.hoursForShowing = this.hoursObjectFromHourMask(schedule.schedule.hour_mask);
      }
    },
    isShowingInSelectedDayOfWeekByDowMask(dowMask, selectedDate) {
      const dayOfWeekNumber = selectedDate.getDay();
      if ((dowMask & (1 << dayOfWeekNumber)) > 0) {
        return true;
      }
      return false;
    },
    onCalendarDayClick(date) {
      this.schedulesForShowingInCalendarCreating = true;
      this.calendarSelectedDate = date;
      this.calendarSelectedDateDayOfWeek = date.getDay();
      this.schedulesForShowingInCalendarBlock = this.extractSchedulesForDate(
        this.calendarSelectedDate,
        this.pagesSchedules
      );
      this.schedulesForShowingInCalendarCreating = false;
    },
    hourMaskFromHoursObject(hours) {
      // тут созадется битовая маска для часов
      let result = 0;

      if (hours.hasOwnProperty(0) && hours[0] === true) {
        result += 1 << 0;
      }
      if (hours.hasOwnProperty(1) && hours[1] === true) {
        result += 1 << 1;
      }
      if (hours.hasOwnProperty(2) && hours[2] === true) {
        result += 1 << 2;
      }
      if (hours.hasOwnProperty(3) && hours[3] === true) {
        result += 1 << 3;
      }
      if (hours.hasOwnProperty(4) && hours[4] === true) {
        result += 1 << 4;
      }
      if (hours.hasOwnProperty(5) && hours[5] === true) {
        result += 1 << 5;
      }
      if (hours.hasOwnProperty(6) && hours[6] === true) {
        result += 1 << 6;
      }
      if (hours.hasOwnProperty(7) && hours[7] === true) {
        result += 1 << 7;
      }
      if (hours.hasOwnProperty(8) && hours[8] === true) {
        result += 1 << 8;
      }
      if (hours.hasOwnProperty(9) && hours[9] === true) {
        result += 1 << 9;
      }
      if (hours.hasOwnProperty(10) && hours[10] === true) {
        result += 1 << 10;
      }
      if (hours.hasOwnProperty(11) && hours[11] === true) {
        result += 1 << 11;
      }
      if (hours.hasOwnProperty(12) && hours[12] === true) {
        result += 1 << 12;
      }
      if (hours.hasOwnProperty(13) && hours[13] === true) {
        result += 1 << 13;
      }
      if (hours.hasOwnProperty(14) && hours[14] === true) {
        result += 1 << 14;
      }
      if (hours.hasOwnProperty(15) && hours[15] === true) {
        result += 1 << 15;
      }
      if (hours.hasOwnProperty(16) && hours[16] === true) {
        result += 1 << 16;
      }
      if (hours.hasOwnProperty(17) && hours[17] === true) {
        result += 1 << 17;
      }
      if (hours.hasOwnProperty(18) && hours[18] === true) {
        result += 1 << 18;
      }
      if (hours.hasOwnProperty(19) && hours[19] === true) {
        result += 1 << 19;
      }
      if (hours.hasOwnProperty(20) && hours[20] === true) {
        result += 1 << 20;
      }
      if (hours.hasOwnProperty(21) && hours[21] === true) {
        result += 1 << 21;
      }
      if (hours.hasOwnProperty(22) && hours[22] === true) {
        result += 1 << 22;
      }
      if (hours.hasOwnProperty(23) && hours[23] === true) {
        result += 1 << 23;
      }

      return result;
    },
    hoursObjectFromHourMask(mask) {
      const hoursObject = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        22: false,
        23: false
      };

      if ((mask & (1 << 0)) > 0) {
        hoursObject[0] = true;
      }
      if ((mask & (1 << 1)) > 0) {
        hoursObject[1] = true;
      }
      if ((mask & (1 << 2)) > 0) {
        hoursObject[2] = true;
      }
      if ((mask & (1 << 3)) > 0) {
        hoursObject[3] = true;
      }
      if ((mask & (1 << 4)) > 0) {
        hoursObject[4] = true;
      }
      if ((mask & (1 << 5)) > 0) {
        hoursObject[5] = true;
      }
      if ((mask & (1 << 6)) > 0) {
        hoursObject[6] = true;
      }
      if ((mask & (1 << 7)) > 0) {
        hoursObject[7] = true;
      }
      if ((mask & (1 << 8)) > 0) {
        hoursObject[8] = true;
      }
      if ((mask & (1 << 9)) > 0) {
        hoursObject[9] = true;
      }
      if ((mask & (1 << 10)) > 0) {
        hoursObject[10] = true;
      }
      if ((mask & (1 << 11)) > 0) {
        hoursObject[11] = true;
      }
      if ((mask & (1 << 12)) > 0) {
        hoursObject[12] = true;
      }
      if ((mask & (1 << 13)) > 0) {
        hoursObject[13] = true;
      }
      if ((mask & (1 << 14)) > 0) {
        hoursObject[14] = true;
      }
      if ((mask & (1 << 15)) > 0) {
        hoursObject[15] = true;
      }
      if ((mask & (1 << 16)) > 0) {
        hoursObject[16] = true;
      }
      if ((mask & (1 << 17)) > 0) {
        hoursObject[17] = true;
      }
      if ((mask & (1 << 18)) > 0) {
        hoursObject[18] = true;
      }
      if ((mask & (1 << 19)) > 0) {
        hoursObject[19] = true;
      }
      if ((mask & (1 << 20)) > 0) {
        hoursObject[20] = true;
      }
      if ((mask & (1 << 21)) > 0) {
        hoursObject[21] = true;
      }
      if ((mask & (1 << 22)) > 0) {
        hoursObject[22] = true;
      }
      if ((mask & (1 << 23)) > 0) {
        hoursObject[23] = true;
      }

      return hoursObject;
    },
    dowMaskFromDaysObject(days) {
      // тут созадется битовая маска (дня недели
      let result = 0;

      if (days.hasOwnProperty('monday') && days.monday === true) {
        result += 1 << 1;
      }
      if (days.hasOwnProperty('tuesday') && days.tuesday === true) {
        result += 1 << 2;
      }
      if (days.hasOwnProperty('wednesday') && days.wednesday === true) {
        result += 1 << 3;
      }
      if (days.hasOwnProperty('thursday') && days.thursday === true) {
        result += 1 << 4;
      }
      if (days.hasOwnProperty('friday') && days.friday === true) {
        result += 1 << 5;
      }
      if (days.hasOwnProperty('saturday') && days.saturday === true) {
        result += 1 << 6;
      }
      if (days.hasOwnProperty('sunday') && days.sunday === true) {
        result += 1 << 0;
      }
      return result;
    },
    daysObjectFromDowMask(mask) {
      const daysObject = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      };
      if ((mask & (1 << 1)) > 0) {
        daysObject.monday = true;
      }
      if ((mask & (1 << 2)) > 0) {
        daysObject.tuesday = true;
      }
      if ((mask & (1 << 3)) > 0) {
        daysObject.wednesday = true;
      }
      if ((mask & (1 << 4)) > 0) {
        daysObject.thursday = true;
      }
      if ((mask & (1 << 5)) > 0) {
        daysObject.friday = true;
      }
      if ((mask & (1 << 6)) > 0) {
        daysObject.saturday = true;
      }
      if ((mask & (1 << 0)) > 0) {
        daysObject.sunday = true;
      }
      return daysObject;
    },
    changeScheduleActionToCreate() {
      this.actionWithSchedule = 'create';
      this.showScheduleBlock = true;
    },
    changeLocationforSchedule() {
      // тут меняем локацию для объекта рассписания, если меняется локация страницы
      this.updatedSchedle.loc_id = this.getlocIdBylocationId(this.updatedPage.base_location);
    },
    getlocIdBylocationId(locationId) {
      let locId = '';
      for (const location of this.locationsList) {
        if (location.id === locationId) {
          locId = location.loc_id;
          break;
        }
      }
      return locId;
    },
    datePickerInScheduleChangedDate(e) {
      // console.log(e);
      this.datePickerInSchedule.startDate = e.startDate;
      this.datePickerInSchedule.endDate = e.endDate;
      this.updatedSchedle.schedule.start = e.startDate / 1000;
      this.updatedSchedle.schedule.stop = e.endDate / 1000;
    },
    showAndHideIframeNotification() {
      // this.showIframeNotification = true;
      // setTimeout(() => {
      //   this.showIframeNotification = false;
      // }, 3000);
      if (this.iframeNotificationShowed === false) {
        this.iframeNotificationShowed = true;
        this.showIframeNotification = true;
        setTimeout(() => {
          this.showIframeNotification = false;
        }, 3000);
      }
    },
    hideIframeNotification() {
      this.showIframeNotification = false;
    },
    backgroundChangerMouseOver() {
      // console.log('over');
      this.backgroundChangerShow = true;
    },
    backgroundChangerMouseLeave() {
      // console.log('over');
      if (!this.backgroundMuteUpload) {
        this.backgroundChangerShow = false;
      }
    },
    logoTopChangerMouseOver() {
      // console.log('over');
      this.logoTopChangerShow = true;
    },
    logoTopChangerMouseLeave() {
      // console.log('over');
      if (!this.logoMuteUpload) {
        this.logoTopChangerShow = false;
      }
    },
    logoFooterChangerMouseOver() {
      // console.log('over');
      this.logoFooterChangerShow = true;
    },
    logoFooterChangerMouseLeave() {
      // console.log('over');
      if (!this.logo_footerMuteUpload) {
        this.logoFooterChangerShow = false;
      }
    },
    iframeLoaded() {
      // console.log('iframe loaded');
      // console.log(event);
      // const loadedIframe = document.getElementById('preview-iframe');
      // const loadedIframeHeight = loadedIframe.height;
      // console.log(loadedIframeHeight);
      this.isIframeLoading = false;
    },
    getLocationNameByPageId(pageId) {
      let result = '';
      if (this.locationsListByPageId && this.locationsListByPageId[pageId]) {
        if (this.locationName(this.locationsListByPageId[pageId])) {
          result = this.locationName(this.locationsListByPageId[pageId]);
        }
      }
      return result;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalPagesService.getPortalPages(this, { query: true });
      portalPagesService.getPortalPages(this, { query: true, useLocationFilter: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMore = true;
      this.$store.commit('portalPagesListClean');
    },
    openPageModal(isNew, Page) {
      this.updatedPageCompany = 'All companys';
      this.showScheduleInfoMessage = false;
      this.showScheduleBlock = false;
      this.updatedSchedle = false;
      this.actionWithSchedule = 'none';
      this.iframeNotificationShowed = false;
      this.showIframeNotification = false;
      this.isIframeLoading = true;
      this.$validator.reset();
      this.isPageModalOpen = true;
      this.isNewPage = false;
      for (const item in this.isUploaded) {
        if (this.isUploaded.hasOwnProperty(item)) {
          this.isUploaded[item] = false;
        }
      }
      if (isNew) {
        this.isNewPage = true;
        this.updatedPage = JSON.parse(JSON.stringify(this.newPage));
        const newSchedule = JSON.parse(JSON.stringify(this.newSchedle));
        this.updatedSchedle = newSchedule;
        this.updatedSchedle.page_id = '';
        this.updatedSchedle.page_url = '';
        this.updatedSchedle.loc_id = this.getlocIdBylocationId(this.updatedPage.base_location);
        this.datePickerInSchedule.startDate = this.updatedSchedle.schedule.start * 1000;
        this.datePickerInSchedule.endDate = this.updatedSchedle.schedule.stop * 1000;
        this.updatedDaysObject = JSON.parse(JSON.stringify(this.newDaysObject));
        this.updatedHoursObject = JSON.parse(JSON.stringify(this.newHoursObject));
        [this.selectedTimeZone] = timeZones.filter((el) => el.offset === 0);
      } else {
        this.updatedPage = JSON.parse(JSON.stringify(Page));
        this.$set(this.updatedPage, 'base_location', this.locationsListByPageId[this.updatedPage.id]);
        this.setSwitchesOnOpen();
        // дальше перезагружаем айфрейм, чтобы показывал актуальные картинки, даже если опять
        // открываем редактирование только - что отребактированный страницы.
        this.testingAuth = false;
        this.showIframeWrapper = false;
        this.$nextTick(() => {
          this.showIframeWrapper = true;
        });
        // тут работа с расписанием показа
        // смотрим есть ли расписание для редактируемой старницы
        if (this.isPageHasScheduleByPageId[this.updatedPage.id] === true) {
          this.showScheduleBlock = true;
          // console.log('Есть')
          this.updatedSchedle = JSON.parse(JSON.stringify(this.pagesSchedulesByPageId[this.updatedPage.id]));
          this.datePickerInSchedule.startDate = this.updatedSchedle.schedule.start * 1000;
          this.datePickerInSchedule.endDate = this.updatedSchedle.schedule.stop * 1000;
          this.actionWithSchedule = 'update';
          this.updatedSchedle.page_id = this.updatedPage.id;
          this.updatedSchedle.page_url = this.updatedPage.url_id;
          this.updatedSchedle.loc_id = this.getlocIdBylocationId(this.updatedPage.base_location);
          this.updatedDaysObject = this.daysObjectFromDowMask(this.updatedSchedle.schedule.dow_mask);
          this.updatedHoursObject = this.hoursObjectFromHourMask(this.updatedSchedle.schedule.hour_mask);
          [this.selectedTimeZone] = timeZones.filter((el) => el.offset === this.updatedSchedle.schedule.utc_diff);
        } else {
          const newSchedule = JSON.parse(JSON.stringify(this.newSchedle));
          this.updatedSchedle = newSchedule;
          this.updatedSchedle.page_id = this.updatedPage.id;
          this.updatedSchedle.page_url = this.updatedPage.url_id;
          this.updatedSchedle.loc_id = this.getlocIdBylocationId(this.updatedPage.base_location);
          this.datePickerInSchedule.startDate = this.updatedSchedle.schedule.start * 1000;
          this.datePickerInSchedule.endDate = this.updatedSchedle.schedule.stop * 1000;
          this.updatedDaysObject = JSON.parse(JSON.stringify(this.newDaysObject));
          this.updatedHoursObject = JSON.parse(JSON.stringify(this.newHoursObject));
          [this.selectedTimeZone] = timeZones.filter((el) => el.offset === 0);
        }
      }
      this.hasAgreement = !!this.updatedPage.agreement;
      watcher.reset();
    },
    uploadImageToServer(type, selector = '') {
      // console.log('checked file!')
      if (selector === '') {
        selector = type;
      }
      this[`${type}MuteUpload`] = true;
      const images = document.querySelector(`.${selector}`);
      if (!images.files.length) {
        this[`${type}MuteUpload`] = false;
        return;
      }

      const formData = new FormData();
      formData.append(images.name, images.files[0]);
      this.axios.post(`${API_URL || ''}/api/portal/admin/upload`, formData).then(
        (response) => {
          // console.log('response:', response);
          this[`${type}MuteUpload`] = false;
          this.isUploaded[type] = true;
          this.updatedPage.interface[type] = response.data.data.file;
          // покзываем уведомление, что картинки еще не сохранены, а будут сохранены только после
          // нажатия кнопки сохранить
          if (this.iframeNotificationShowed === false) {
            this.iframeNotificationShowed = true;
            this.showIframeNotification = true;
            setTimeout(() => {
              this.showIframeNotification = false;
            }, 3000);
          }
        },
        (err) => {
          this[`${type}MuteUpload`] = false;
          console.warn(err);
        }
      );
    },
    cancelEditMode() {
      this.isPageModalOpen = false;
      this.isDeleteOpen = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    updatePage(Page) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.hasAgreement) {
            Page.agreement = '';
          }
          portalPagesService.updatePage(this, Page);
        }
      });
    },
    createPage(Page) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.hasAgreement) {
            Page.agreement = '';
          }
          portalPagesService.createPage(this, Page);
        }
      });
    },
    copyPage(Page) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const copy = JSON.parse(JSON.stringify(Page));
          portalPagesService.clonePage(this, copy);
        }
      });
    },
    deletePage() {
      portalPagesService.deletePage(this, this.updatedPage);
    },
    copyUrlFromInput(inputRef) {
      this.$refs[inputRef].select();
      document.execCommand('copy');
    },
    getWithQuery() {
      this.clearQuery();
      // portalPagesService.getPortalPages(this, { query: true });
      portalPagesService.getPortalPages(this, { query: true, useLocationFilter: true });
    },
    handleSelectColor(payload) {
      if (this.updatedPage === false || this.updatedPage.interface.theme_color === payload) {
        return;
      }
      this.updatedPage.interface.theme_color = payload;
    },
    handleSelectType(payload) {
      if (this.updatedPage === false || this.updatedPage.interface.theme_type === payload) {
        return;
      }
      this.updatedPage.interface.theme_type = payload;
    },
    handleCloseDeleteDialog() {
      this.isDeleteOpen = false;
    },
    setSwitchesOnOpen() {
      this.hasSupport = this.updatedPage.support !== undefined && this.updatedPage.support !== '';
    }
  },
  // created() {
  //   helpers.setBirthdaysCalendarLocale();
  //   this.clearQuery();
  //   portalPagesService.getPortalPages(this, { query: true });
  //   // portalPagesScheduleService.getPortalPagesSchedules(this);
  // },
  created() {
    this.baseURL = this.portalBaseUrl;
    this.baseURLForImgs = this.portalBaseUrlForImgs;
    helpers.setBirthdaysCalendarLocale();
    this.clearQuery();

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }


    // portalPagesService.getPortalPages(this, { query: true });
    portalPagesService.getPortalPages(this, { query: true, useLocationFilter: true });
    // portalPagesScheduleService.getPortalPagesSchedules(this);
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.break-all {
  word-break: break-all;
}
.spinner-in-schedules {
  position: relative;
  min-height: 50px;
}
.cool-modern-selector input {
  display: none !important;
}

.cool-modern-selector input[type='checkbox'] + label {
  display: inline-block;
  padding-right: 20px;
  border-radius: 6px;
  background: #dddddd;
  color: #939393;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 3px 3px 3px 0;
  user-select: none;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cool-modern-selector-hours input[type='checkbox'] + label {
  width: 53px !important;
}

.cool-modern-selector input[type='checkbox']:checked + label {
  background: #4dbd74;
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.days-of-week .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.img-recommendations {
  position: absolute;
  bottom: 5px;
  color: #ffffff;
  font-size: 0.8rem;
  text-shadow: 4px 5px 60px rgba(0.2, 0.2, 0.2, 0.8);
}
.left-column-in-modal-portal-pages {
  max-width: 450px;
}
.portal-palette-wrapper {
  max-width: 352px;
}
.images-for-portal-page {
}
.images-for-portal-page-item {
  margin: 5px;
}
.input-group {
  z-index: auto;
}
.img-thumbnail {
  display: block;
  max-width: 200px;

  border: 0;
}
.img-thumbnail.img-thumbnail-in-modal {
  margin: 5px !important;
  display: block;
  width: 130px !important;
  border: 0 !important;
  /*background-color: transparent !important;*/
  padding: 1px !important;
}

.button-color-block {
  width: 100%;
  height: 100%;
}

.button-color-container {
  display: block;
  min-width: 150px;
  min-height: 20px;

  border: 1px solid gray;
}

#preview-iframe {
  display: block;

  border: none;
}

.portal-iframe-container {
  position: sticky;
  top: 10px;
}
.loader-backdrop-portalpages:after {
  position: absolute;
  top: 30px;
}

.custom-padding-for-in-iterator-card-data-items {
  padding-left: 0.5em;
}

.custom-padding-bottom-for-description {
  padding-bottom: 0.25em;
}
.data-iterator-data-categories {
  font-size: 1.1em;
  font-weight: 500;
}

.logo-top-change {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  left: 0%;
  position: absolute;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.46);
}
.logo-top-change-wrapper {
  top: 40px;
  width: 100%;
  left: 0%;
  position: absolute;
  height: 150px;
}
.logo-top-change-wrapper-for-new-page {
  top: 0px;
  width: 100%;
  left: 0%;
  position: absolute;
  height: 150px;
}

.logo-footer-change {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  left: 0%;
  position: absolute;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.46);
}

.logo-footer-change-wrapper {
  width: 100%;
  left: 0%;
  bottom: 0px;
  position: absolute;
  height: 170px;
}
.logo-footer-change-wrapper-for-new-page {
  width: 100%;
  left: 0%;
  bottom: 0px;
  position: absolute;
  height: 170px;
}

.background-change {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  left: 0%;
  position: absolute;
  height: 170px;
  background-color: rgba(0, 0, 0, 0.46);
}

.background-change-wrapper {
  width: 100%;
  left: 0%;
  top: 300px;
  position: absolute;
  height: 150px;
}
.background-change-wrapper-for-new-page {
  width: 100%;
  left: 0%;
  top: 258px;
  position: absolute;
  height: 150px;
}

.logo-footer-change span,
.logo-top-change span,
.background-change span {
  background-color: transparent !important;
  border-width: 0px;
}
.logo-footer-change button,
.logo-top-change button,
.background-change button {
  background-color: transparent !important;
  border-width: 0px;
}
.logo-footer-change i,
.logo-top-change i,
.background-change i {
  font-size: 3em;
}
.iframe-wrapper {
  position: relative;
  width: 375px !important;
  height: 1100px !important;
}
.iframe-wrapper-for-new-page {
  position: relative;
  width: 375px !important;
  /*height: 1100px !important;*/
}

.iframe-wrapper-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: left;
  height: 667px !important;
  overflow: auto;
}
.iframe-wrapper-wrapper-for-new-page {
  display: flex;
  flex-direction: row;
  justify-content: left;
  height: 668px !important;
  overflow: auto;
}
.logo-changers-color {
  color: white !important;
}
.logo-changers-photo-formats-to-left {
  position: relative;
  left: 12px;
}
.iframe-wrapper-notify {
  position: absolute;
  height: 100%;
  width: 95%;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.71);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.iframe-wrapper-notify-for-new-page {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.71);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.iframe-wrapper-notify-inner {
  padding: 2em;
}
.iframe-wrapper-notify-close {
  position: absolute;
  top: 1px;
  right: 10px;
  font-size: 2em;
}
</style>

<style lang="scss">
.modal-pages .modal-dialog {
  max-width: 1000px;
}
.portal-pages-schedules-block {
  min-width: 280px;
}
.portal-pages-page-card {
  min-width: 183px;
}
/*стили для календаря*/
.datepicker {
  display: flex;
  justify-content: center;
}
.datepicker-wrapper {
  z-index: 0;
  width: 251px;
}
.datepicker-inner {
  width: 251px;
}
.datepicker-popup {
  z-index: 0;
  position: relative !important;
  border: 0 solid #fff;
  border-radius: 0;
  background: #fff;
  margin-top: 0px;
  box-shadow: none;
}
.datepicker-ctrl p,
.datepicker-ctrl span,
.datepicker-body span {
  display: inline-block;
  width: 33px;
  line-height: 33px;
  height: 33px;
  border-radius: 0;
}
.day-cell div {
  position: relative;
}
.day-cell div div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.day-icon {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background-color: #ffa538;
  border-radius: 50%;
  font-weight: 800;
  font-size: 10px;
  width: 12px;
  line-height: 12px;
  height: 12px;
}
.day-icon small {
  font-weight: bold;
}
.datepicker-dateRange-item-active:hover,
.datepicker-dateRange-item-active {
  background: #20a8d8 !important;
  color: white !important;
}

.loader-backdrop-portalads:after {
  position: absolute;
  top: 30px;
}
</style>
